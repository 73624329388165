export const apiConfig = {
    powerPointEndPoint: 'https://idpowertoys-ci.azurewebsites.net'
}

export const msalConfig = {
    clientId: '50827722-4f53-48ba-ae58-db63bb53626b'
}

export const envConfig = {
    envName: '[CI]'
}
